import { makeStyles } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface ProfilePictureUpdatorProps {
    img: string;
    handleUpdateAvatar: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        position: 'relative',
        marginRight: '2rem',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '50%',
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: 'rgba(100,100,100,.2)',
        top: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0',
        '&:hover': {
            opacity: '100',
        },
    },
    overlayBtn: {
        width: '100%',
        height: '100%',
    },
}));

const ProfilePictureUpdator = (props: ProfilePictureUpdatorProps) => {
    const classes = useStyles();

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
            let image = e.target.files[0];
            if (
                image.type === 'image/jpeg' ||
                image.type === 'image/jpg' ||
                image.type === 'image/png'
            ) {
                props.handleUpdateAvatar(image);
            }
        }
    };

    return (
        <div className={classes.root}>
            <img className={classes.image} src={props.img} alt="user" />
            <div className={classes.overlay}>
                <label>
                    <input
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e)}
                        style={{ display: 'none' }}
                        id="avatar_update"
                        type="file"
                    />
                    <CloudUploadIcon style={{ fontSize: '4rem' }} />
                </label>
            </div>
        </div>
    );
};

export default ProfilePictureUpdator;
