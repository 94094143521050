import React, { useEffect, useState } from 'react';
import {
    Backdrop,
    CircularProgress,
    Fade,
    InputAdornment,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import firebase from '../../utils/auth';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MuiAlert from '@material-ui/lab/Alert';
import { validateEmail } from 'utils/InputValidator';
import countries from '../../assets/countries.json';

import './Donation.scss';

const Alert = (props: any) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
    paymentSelectionButton: {
        minWidth: '160px',
        fontWeight: 'bold',
    },
    pricingButtons: {
        minWidth: '100px',
        fontWeight: 'bold',
    },
    select: {
        height: '40px',
        marginLeft: 0,
    },
    centeredContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#135279',
    },
    donationMain: {
        padding: '2rem',
        backgroundColor: 'white',
        maxWidth: '50%',
        width: '100%',
        margin: '3rem auto',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
    priceContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        gap: '5px',
        placeItems: 'center',
        marginBottom: '15px',
    },
    input: {
        border: '1px solid #cb6025',
        borderRadius: '4px',
        padding: '12px 10px',
        transition: 'border-color 0.3s',
        marginBottom: '15px',
    },
    focusedInput: {
        borderColor: '#cb6025',
        borderWidth: '2px',
    },
}));

const Wrapper = (props: any) => {
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
    );

    return (
        <Elements
            stripe={stripePromise}
            options={{ mode: 'payment', amount: 1099, currency: 'usd' }}
        >
            <Donation {...props} />
        </Elements>
    );
};

const Donation: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const [snackData, setSnackData] = useState({
        show: false,
        type: 'warning',
        msg: '',
    });
    const [donationData, setDonationData] = useState({
        selected_payment: 'monthly',
        amount: '20',
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: countries?.countries[0].name || '',
        payment_method: 'Card',
    });
    const [isUploadingData, setIsUploadingData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cardNumber, setCardNumber] = useState<any>(null);
    const [cardExpiry, setCardExpiry] = useState<any>(null);
    const [cardCvc, setCardCvc] = useState<any>(null);
    const prices = ['20', '40', '60', '80', '100', '120'];

    const getSelectedPayment = () => donationData.selected_payment;
    const getSelectedAmount = () => donationData.amount;

    const getButtonColor = (value: string | number, func: Function) => {
        return func() === value ? 'secondary' : 'default';
    };

    const getButtonVariant = (value: string | number, func: Function) => {
        return func() === value ? 'contained' : 'outlined';
    };

    const customValueChanged = (e: {
        target: { name: string; value: unknown };
    }) => {
        setDonationData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const validateInputs = () => {
        const {
            amount,
            first_name,
            last_name,
            email,
            address,
            city,
            state,
            country,
        } = donationData;
        if (!amount || Number(amount) < 10) {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'Amount must be at least $10.',
            });
            return true;
        }
        if (!first_name || !last_name) {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'First name and last name are required.',
            });
            return true;
        }
        if (!email || !validateEmail(email)) {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'A valid email is required.',
            });
            return true;
        }
        if (!address || !city || !state || !country) {
            setSnackData({
                show: true,
                type: 'warning',
                msg: 'Address, city, state, and country are required.',
            });
            return true;
        }
        return false;
    };

    const handleDonate = async (event: React.FormEvent) => {
        event.preventDefault();
        if (validateInputs()) return;

        setIsUploadingData(true);
        try {
            if (donationData.payment_method !== 'Card') {
                const result = await firebase.saveDonationInformation({
                    user: donationData,
                });
                if (result?.data.error)
                    throw new Error(result.data.error.message);

                const subscribed = await firebase.createStripeSubscription({
                    customPrice: Number(donationData.amount),
                    payment_method: donationData.payment_method,
                    payment_type: donationData.selected_payment,
                });
                if (subscribed?.data.error)
                    throw new Error(subscribed.data.error.message);

                if (subscribed?.data.session)
                    window.open(subscribed.data.session.url);
            } else if (stripe && cardNumber && cardExpiry && cardCvc) {
                const { error, paymentMethod } =
                    await stripe.createPaymentMethod({
                        type: 'card',
                        card: cardNumber,
                        billing_details: {
                            name: `${donationData.first_name} ${donationData.last_name}`,
                            email: donationData.email,
                        },
                    });
                if (error) throw new Error(error.message);

                const response = await firebase.createStripeCustomer({
                    name: `${donationData.first_name} ${donationData.last_name}`,
                    email: donationData.email,
                    paymentMethodId: paymentMethod.id,
                });
                if (response?.data?.error)
                    throw new Error(
                        'Something went wrong while creating the customer.',
                    );

                const customerId = response?.data?.data?.customer?.id;
                const product = await firebase.createStripeProduct({
                    customerId,
                    name: `${donationData.first_name} ${donationData.last_name} Donation`,
                    price: Number(donationData.amount),
                });
                if (product?.data.error)
                    throw new Error(
                        'Something went wrong while creating the product.',
                    );

                const productId = product?.data?.product?.id;
                const subscribed = await firebase.createStripeSubscription({
                    customerId,
                    customPrice: Number(donationData.amount),
                    payment_type: donationData.selected_payment,
                    productId,
                    paymentMethodId: paymentMethod.id,
                });
                console.log('🚀 ~ handleDonate ~ subscribed:', subscribed);
                if (subscribed?.data.error)
                    throw new Error(
                        'Something went wrong while creating the subscription.',
                    );

                setSnackData({
                    show: true,
                    type: 'success',
                    msg: 'Thank you for donating at N-Vest Partners. You will be redirected to the home page shortly.',
                });
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
            }
        } catch (error: any) {
            setSnackData({
                show: true,
                type: 'error',
                msg: error.message || 'Something went wrong.',
            });
        } finally {
            setIsUploadingData(false);
        }
    };

    const handleSnackClose = () => {
        setSnackData((prev) => ({ ...prev, show: false }));
    };

    useEffect(() => {
        if (
            elements &&
            donationData.payment_method === 'Card' &&
            !cardNumber &&
            !cardExpiry &&
            !cardCvc
        ) {
            const cardNumberElement = elements.create('cardNumber', {
                classes: { base: classes.input, focus: classes.focusedInput },
                showIcon: true,
            });
            const cardExpiryElement = elements.create('cardExpiry', {
                classes: { base: classes.input, focus: classes.focusedInput },
            });
            const cardCvcElement = elements.create('cardCvc', {
                classes: { base: classes.input, focus: classes.focusedInput },
            });

            cardNumberElement.mount('#card-number');
            cardExpiryElement.mount('#card-expiry');
            cardCvcElement.mount('#card-cvc');

            setCardNumber(cardNumberElement);
            setCardExpiry(cardExpiryElement);
            setCardCvc(cardCvcElement);
        }

        return () => {
            if (cardNumber) cardNumber.destroy();
            if (cardExpiry) cardExpiry.destroy();
            if (cardCvc) cardCvc.destroy();
        };
    }, [elements, donationData.payment_method]);

    return (
        <div className={classes.centeredContent}>
            <Backdrop open={isUploadingData} style={{ zIndex: 9999999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackData.show}
                autoHideDuration={4000}
                onClose={handleSnackClose}
            >
                <Alert onClose={handleSnackClose} severity={snackData.type}>
                    {snackData.msg}
                </Alert>
            </Snackbar>

            <div className={classes.donationMain}>
                <Typography variant="h4" align="center">
                    Support N-Vest Partners
                </Typography>
                <Typography
                    variant="body2"
                    align="center"
                    className="g_mt1 g_mb1"
                >
                    Thank you for your donation and support of N-Vest Partners.
                    Your donation allows us to connect great organizations with
                    great people!
                </Typography>

                <div
                    className="g_row_center g_mt2 g_mb2"
                    style={{ gap: '10px' }}
                >
                    <Button
                        className={classes.paymentSelectionButton}
                        variant={getButtonVariant(
                            'monthly',
                            getSelectedPayment,
                        )}
                        color={getButtonColor('monthly', getSelectedPayment)}
                        onClick={() =>
                            customValueChanged({
                                target: {
                                    name: 'selected_payment',
                                    value: 'monthly',
                                },
                            })
                        }
                    >
                        Monthly
                    </Button>
                    <Button
                        className={classes.paymentSelectionButton}
                        variant={getButtonVariant(
                            'oneTime',
                            getSelectedPayment,
                        )}
                        color={getButtonColor('oneTime', getSelectedPayment)}
                        onClick={() =>
                            customValueChanged({
                                target: {
                                    name: 'selected_payment',
                                    value: 'oneTime',
                                },
                            })
                        }
                    >
                        One-Time
                    </Button>
                </div>

                <div className={classes.priceContainer}>
                    {prices.map((price) => (
                        <Button
                            key={price}
                            className={classes.pricingButtons}
                            variant={getButtonVariant(price, getSelectedAmount)}
                            color={getButtonColor(price, getSelectedAmount)}
                            onClick={() =>
                                customValueChanged({
                                    target: {
                                        name: 'amount',
                                        value: price,
                                    },
                                })
                            }
                        >
                            ${price}
                        </Button>
                    ))}
                </div>

                <div className="g_row_center g_mb1 g_mt2">
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="amount"
                        type="number"
                        value={donationData.amount}
                        onChange={customValueChanged}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Enter Amount"
                    />
                </div>

                <Typography variant="h4" className="g_mb1">
                    Your Info
                </Typography>
                {[
                    'first_name',
                    'last_name',
                    'email',
                    'address',
                    'city',
                    'state',
                    'zip',
                ].map((field) => (
                    <div className="g_mb1" key={field}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            name={field}
                            placeholder={field
                                .replace('_', ' ')
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            onChange={customValueChanged}
                        />
                    </div>
                ))}
                <Select
                    fullWidth
                    value={donationData.country}
                    name="country"
                    variant="outlined"
                    onChange={(e, child) =>
                        customValueChanged({
                            target: {
                                name: e.target.name || 'country',
                                value: e.target.value,
                            },
                        })
                    }
                    className={`g_mb1 ${classes.select}`}
                >
                    {countries.countries.map((country) => (
                        <MenuItem key={country.name} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </Select>

                <div className="g_mt1 g_mb1">
                    <Typography variant="h4">Payment Info</Typography>
                </div>

                <Select
                    fullWidth
                    value={donationData.payment_method}
                    name="payment_method"
                    variant="outlined"
                    onChange={(e, child) =>
                        customValueChanged({
                            target: {
                                name: e.target.name || 'payment_method',
                                value: e.target.value,
                            },
                        })
                    }
                    className={`g_mb1 ${classes.select}`}
                >
                    {donationData.selected_payment !== 'monthly' && (
                        <MenuItem value="Cash App Pay">Cash App Pay</MenuItem>
                    )}
                    <MenuItem value="Card">Card</MenuItem>
                </Select>

                {donationData.payment_method === 'Card' && (
                    <>
                        <div id="card-number" className={classes.input}></div>
                        <div id="card-expiry" className={classes.input}></div>
                        <div id="card-cvc" className={classes.input}></div>
                    </>
                )}

                <Button
                    className="g_primary_btn"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleDonate}
                >
                    {donationData.payment_method === 'Card'
                        ? 'Donate Now'
                        : 'Cash App Pay Now'}
                </Button>
            </div>
        </div>
    );
};

export default Wrapper;
