import {
    Avatar,
    Box,
    Button,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import firebase from 'utils/auth';

interface ReviewCardProps {
    reviewerName: string;
    createdOn: Date;
    title: string;
    review: string;
    userId: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    large: {
        width: '8rem',
        height: '8rem',
    },
    small: {
        width: '4rem',
        height: '4rem',
    },
    review: {
        whiteSpace: 'pre-line',
    },
}));

const ReviewCard = (props: ReviewCardProps) => {
    const classes = useStyles();

    const theme = useTheme();
    const b_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const [imageSrc, setImgeSrc] = useState('');

    useEffect(() => {
        if (props.userId) {
            firebase.storage
                .ref()
                .child(`avatars/${props.userId}.jpg`)
                .getDownloadURL()
                .then((url) => {
                    if (setImgeSrc) {
                        setImgeSrc(url);
                    }
                });
        }
    }, [props.userId]);

    return (
        <div className="op_review_card">
            <div className="op_review_card_image">
                {b_sm ? (
                    <Avatar
                        alt={props.reviewerName}
                        src={imageSrc}
                        className={classes.large}
                    />
                ) : (
                    <Avatar
                        alt={props.reviewerName}
                        src={imageSrc}
                        className={classes.small}
                    />
                )}
            </div>
            <div className="op_review_card_details">
                <Box mb="1rem">
                    <Typography variant="h6">{props.title}</Typography>
                    <Typography variant="caption">
                        {props.reviewerName} -{' '}
                        {dayjs(props.createdOn).format('MMMM YYYY')}
                    </Typography>
                </Box>
                <Typography className={classes.review} variant="body1">
                    {props.review}
                </Typography>
                <div className="op_review_card_review_section">
                    <Typography variant="h6">Was this review ….?</Typography>
                    <div className="op_review_card_review_section_buttons">
                        <Button variant="contained" color="primary">
                            UseFul
                        </Button>
                        <Button variant="contained" color="primary">
                            Not UseFul
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewCard;
