import { makeStyles } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface CoverPictureUploaderProps {
    img: string;
    handleUpdateAvatar: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    image: {
        width: '100%',
        height: '300px',
        objectFit: 'cover',
    },
    overlay: {
        position: 'relative',
        width: '100%',
        height: '100%',

        top: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '100',
    },
    upload: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

const CoverPictureUploader = (props: CoverPictureUploaderProps) => {
    const classes = useStyles();

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
            let image = e.target.files[0];
            if (
                image.type === 'image/jpeg' ||
                image.type === 'image/jpg' ||
                image.type === 'image/png'
            ) {
                props.handleUpdateAvatar(image);
            }
        }
    };

    return (
        <div className={classes.root}>
            {props.img && (
                <img
                    className={classes.image}
                    src={props.img}
                    alt="cover-background"
                />
            )}
            <div className={classes.overlay}>
                <label className={classes.upload}>
                    <input
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e)}
                        style={{ display: 'none' }}
                        id="cover_update"
                        type="file"
                    />
                    <CloudUploadIcon
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                    />
                    &nbsp;
                </label>
            </div>
        </div>
    );
};

export default CoverPictureUploader;
