//import React from 'react';
import React, {
  SyntheticEvent,
  useCallback,
  /*useEffect,*/ useState,
} from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./Forgot.scss";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import firebase from "../../utils/auth";
import { AppRoutes } from "../../App";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF9E54",
    color: "#FFFFFF",
  },
}));

type State = {
  email: string;
};

const initialState = {
  email: "",
  error: null,
};

const Forgot: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = useState<State>(initialState);
  const [error, setError] = useState("");

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setState((s) => ({ ...s, [name]: value, error: "" }));
  }, []);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      const { email } = state;

      try {
        const res = await firebase.resetPassword(email);
        console.log("res: ", res);
        alert("Password Reset Email Sent!");
        //setEmailHasBeenSent(true);
        //setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      } catch (error) {
        // error handling
        setError("Error resetting password");
        console.error("Error resetting password", error);
      }
    },
    [state]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          {error && <h4>{error}</h4>}

          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="text"
            value={state.email}
            autoComplete="email"
            onChange={handleChange}
            style={{
              backgroundColor: "#f3f9fd",
              borderTopRightRadius: ".2rem",
              borderTopLeftRadius: ".2rem",
            }}
          />

          <Button fullWidth type="submit" color="secondary" variant="contained">
            Send me reset link
          </Button>
          <Grid container>
            <Grid item>
              <Link style={{color: "white"}} to={AppRoutes.Login}>Back to login page</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default Forgot;
