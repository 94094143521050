import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { Messages } from 'components/Messages/Messages';
import { NewsFeed } from 'components/NewsFeed/NewsFeed';
//import VolunteerBroadCast from 'components/VolunteerBroadCast/VolunteerBroadCast';

import './Inbox.scss';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        communicationPanel: {
            color: '#000',
            fontColor: '#000',
            maxWidth: '80rem',
        },
    }),
);

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
type CommunicationPropsType = {
    totalUnreadChats: number;
};

const Inbox: React.FC<CommunicationPropsType> = (
    props: CommunicationPropsType,
) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setSelectedTab(newValue);
    };

    return (
        <Box className="communication-container">
            {/* Sidebar */}
            <Tabs
                className="sidebar-tabs"
                textColor="primary"
                indicatorColor="primary"
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleTabChange}
            >
                <Tab
                    label={`Messages${
                        props.totalUnreadChats > 0
                            ? ` (${props.totalUnreadChats})`
                            : ''
                    }`}
                    {...a11yProps(0)}
                />
                <Tab label="NewsFeed" {...a11yProps(1)} />
                {/* <Tab label="Organization Messages" {...a11yProps(2)} /> */}
            </Tabs>

            {/* Content Area */}
            <Box sx={{ flexGrow: 1 }}>
                <TabPanel
                    index={0}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <Messages />
                </TabPanel>
                <TabPanel
                    index={1}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <NewsFeed />
                </TabPanel>
                {/* <TabPanel
                    index={2}
                    value={selectedTab}
                    className={classes.communicationPanel}
                >
                    <VolunteerBroadCast />
                </TabPanel> */}
            </Box>
        </Box>
    );
};

export default Inbox;
