import { Box, Checkbox, TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState, ChangeEvent } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import firebase from '../../utils/auth';
import GoogleLocation from '../AutocompleteLocation/Location';

const useStyles = makeStyles({
    '&.MuiChip-root': {
        height: '0px',
    },
    projectFilters: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    locationFilter: {
        width: '300px',
    },
});

export default function CausesFilter({
    onFilterChange,
}: {
    onFilterChange: (a: string) => void;
}): JSX.Element {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [causes, setCauses] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [address, setAddress] = useState<any>('');
    const [selectedDuration, setSelectedDuration] = useState<string[]>([]);
    const durations = [
        {
            label: '1-2 hours/week',
            value: 1,
        },
        {
            label: '3-4 hours/week',
            value: 2,
        },
        {
            label: '5-6 hours/week',
            value: 3,
        },
        {
            label: '7 Hours+/week',
            value: 4,
        },
    ];
    const handleChange = (e: ChangeEvent<{}>, data: string[]) => {
        setSelected((preState) => {
            const newState = [...preState];
            const filterCause = data.length
                ? `causes:"${data[data.length - 1]}"`
                : '';

            if (newState.indexOf(filterCause) >= 0) {
                newState.splice(newState.indexOf(filterCause), 1);
            } else if (filterCause) {
                newState.push(filterCause);
            } else {
                newState.length = 0;
            }
            return newState;
        });
    };

    const handleDurationChange = (
        e: ChangeEvent<{}>,
        duration: { label: string; value: number }[],
    ) => {
        setSelectedDuration((preState) => {
            const newState = [...preState];
            const filterCause = duration.length
                ? `activity_duration_per_week = ${+duration[duration.length - 1]
                      .value}`
                : '';

            if (newState.indexOf(filterCause) >= 0) {
                newState.splice(newState.indexOf(filterCause), 1);
            } else if (filterCause) {
                newState.push(filterCause);
            } else {
                newState.length = 0;
            }
            return newState;
        });
    };
    useEffect(() => {
        const updateList = async () => {
            setIsLoading(true);
            let list = await firebase.getAllCauses();
            setCauses(list);
            setIsLoading(false);
        };
        updateList();
    }, []);

    useEffect(() => {
        if (selected.length > 0) {
            onFilterChange(
                `${selected.join(' OR ')}${
                    selectedDuration.length
                        ? ' AND ' + selectedDuration.join(' OR ')
                        : ''
                }${
                    address?.label
                        ? ' AND ' +
                          `location.address.label: "${address?.label}"`
                        : ''
                }`,
            );
        } else if (selectedDuration.length > 0) {
            onFilterChange(selectedDuration.join(' OR '));
        } else if (address?.label) {
            onFilterChange(`location.address.label : "${address.label}"`);
        } else {
            onFilterChange('');
        }
    }, [selectedDuration, selected, address, onFilterChange]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <>
            {!isLoading && (
                <Box className={classes.projectFilters}>
                    <div className="">
                        <Autocomplete
                            multiple
                            size="small"
                            style={{ width: 300 }}
                            options={causes}
                            disableCloseOnSelect
                            id="checkboxes-tags-demo"
                            getOptionLabel={(option) => option}
                            onChange={handleChange}
                            renderOption={(option, { selected }) => (
                                <>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </>
                            )}
                            limitTags={2}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Enter Causes"
                                    label="Causes"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                        />
                    </div>
                    <div className="">
                        <Autocomplete
                            multiple
                            size="small"
                            style={{ width: 300 }}
                            options={durations}
                            disableCloseOnSelect
                            id="checkboxes-duration"
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            onChange={handleDurationChange}
                            renderOption={(option, { selected }) => {
                                return (
                                    <>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.label}
                                    </>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Duration per week"
                                    label="Duration per week"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                        />
                    </div>
                    <div className={classes.locationFilter}>
                        <GoogleLocation
                            selectProps={{
                                isClearable: true,
                                value: address,
                                onChange: (val: any) => {
                                    setAddress(val);
                                    console.log('Address', val);
                                },
                                placeholder: 'Location',
                            }}
                        />
                    </div>
                </Box>
            )}
        </>
    );
}
