import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { ProjectType } from 'types';
import firebase from 'utils/auth';

export default function useVolunteerProjects() {
    const { userData, isChecking } = useContext(UserContext);
    const [projects, setProjects] = useState<ProjectType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isChecking || !userData) {
            return;
        }
        setIsLoading(true);
        const unsub = firebase.db
            .collection('projects')
            .where('team_member_ids', 'array-contains', userData.id)
            .onSnapshot((snapshot) => {
                if (!snapshot.empty) {
                    const projects: ProjectType[] = [];
                    snapshot.docs.forEach((doc) => {
                        projects.push(doc.data() as ProjectType);
                    });
                    setProjects(projects);
                }
                setIsLoading(false);
            });

        return unsub;
    }, [userData, isChecking]);

    return { projects, isLoading };
}
