import React, { createContext, useCallback, useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { useEffect } from 'react';
import firebase from '../utils/auth';
import { ApprovalType, OrganizationType } from 'types';

export type ApprovalContextType = {
    approvals: Array<ApprovalType>;
    totalApprovalCount: number;
};

export const ApprovalContext = createContext<ApprovalContextType>({
    approvals: [],
    totalApprovalCount: 0,
});

type ApprovalProviderProps = {
    children: React.ReactNode;
};

const ApproverProvider = ({ children }: ApprovalProviderProps) => {
    const { isLoggedIn, userData } = useContext(UserContext);
    const [totalApprovalCount, setTotalApprovalCount] = useState<number>(0);
    const [approvals, setApprovals] = useState<Array<ApprovalType>>([]);

    useEffect(() => {
        if (isLoggedIn) {
            setTotalApprovalCount(approvals.length);
        }
    }, [approvals, isLoggedIn]);

    const updateApprovalList = (data: OrganizationType) => {
        setApprovals(data.volunteerRequest as Array<ApprovalType>);
    };

    const setUpNotificationListener = useCallback(() => {
        let unsub = () => {
            return;
        };
        if (firebase.auth.currentUser) {
            unsub = firebase.db
                .collection('organizations')
                .where('uid', '==', userData?.id)
                //.where('volunteerRequest.organizationId', '==', userData?.id)
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as OrganizationType;
                        //TODO: Do I need this ?
                        const participantIndex =
                            data.volunteerRequest?.findIndex(
                                (approval) =>
                                    approval.organizationId ===
                                    (firebase.auth.currentUser?.uid as string),
                            );

                        if (participantIndex >= 0 && change.type === 'added') {
                            updateApprovalList(data);
                        }
                        if (
                            participantIndex >= 0 &&
                            change.type === 'modified'
                        ) {
                            updateApprovalList(data);
                        }
                        if (change.type === 'removed') {
                            // removing
                        }
                    });
                });
        }
        return unsub;
    }, [userData]);

    useEffect(() => {
        let unsub: any = () => {
            return;
        };
        if (isLoggedIn && userData) {
            unsub = setUpNotificationListener();
        }

        return unsub;
    }, [isLoggedIn, userData, setUpNotificationListener]);

    const contextValue: ApprovalContextType = {
        approvals,
        totalApprovalCount,
    };

    return (
        <ApprovalContext.Provider value={contextValue}>
            {children}
        </ApprovalContext.Provider>
    );
};

export default ApproverProvider;
