import Resizer from 'react-image-file-resizer';

export const prepareAvatar = (file: File) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 200, 200, 'JPEG', 90, 0,
  (file) => {
    resolve(file);
  },
  'file'
  );
});

export const prepareProjectImage = (file: File) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 1200, 1200, 'JPEG', 90, 0,
  (file) => {
    resolve(file);
  },
  'file'
  );
});

export const prepareCoverImage = (file: File) => new Promise(resolve => {
	Resizer.imageFileResizer(file, 2000, 2000, 'JPEG', 100, 0,
		(file) => {
			resolve(file);
		},
		'file'
	);
});

export const waitForUploadTaskCompletion = (task: any) => new Promise((resolve) => {
	task.on('state_changed',
    null,
    (error: any) => {
      throw error;
    },
    () => {
      task.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
        resolve(downloadURL);
      });
    }
  )
});
