import React, { createContext, useEffect, useState } from 'react';
import firebase from '../utils/auth';

export const CommonDataContext = createContext();

const CommonDataContextProvider = (props) => {
    const [approvedCompanyNames, setApprovedCompanyNames] = useState(null);
    const [volunteerLocations, setVolunteerLocations] = useState(null);
    const [volunteerSkills, setVolunteerSkills] = useState(null);
    const [volunteerTimings, setVolunteerTimings] = useState(null);

    // true when width < 900
    const [mobileViewBig, setMobileViewBig] = useState(window.innerWidth < 900);
    // true when width < 800
    const [mobileViewMid, setMobileViewMid] = useState(window.innerWidth < 800);
    // true when width < 600
    const [mobileViewSm, setMobileViewSm] = useState(window.innerWidth < 600);

    useEffect(() => {
        function setWidth() {
            if (window.innerWidth <= 900 && !mobileViewBig) {
                setMobileViewBig(true);
            } else if (window.innerWidth > 900 && mobileViewBig) {
                setMobileViewBig(false);
            }
            if (window.innerWidth <= 800 && !mobileViewMid) {
                setMobileViewMid(true);
            } else if (window.innerWidth > 800 && mobileViewMid) {
                setMobileViewMid(false);
            }
            if (window.innerWidth <= 600 && !mobileViewSm) {
                setMobileViewSm(true);
            } else if (window.innerWidth > 600 && mobileViewSm) {
                setMobileViewSm(false);
            }
        }
        window.addEventListener('resize', setWidth);
        return () => {
            window.removeEventListener('resize', setWidth);
        };
    }, [mobileViewSm, mobileViewMid, mobileViewBig]);

    useEffect(() => {
        const getList = async () => {
            let list = await firebase.getApprovedCompanyNames();
            setApprovedCompanyNames(list);
            let loclist = await firebase.getVolunteerLocations();
            setVolunteerLocations(loclist);
            let skilllist = await firebase.getAllCategories();
            setVolunteerSkills(skilllist);
            let timminglist = await firebase.getVolunteerTimings();
            setVolunteerTimings(timminglist);
        };
        getList();
    }, []);

    return (
        <CommonDataContext.Provider
            value={{
                mobileViewSm,
                mobileViewMid,
                mobileViewBig,
                approvedCompanyNames,
                volunteerLocations,
                volunteerSkills,
                volunteerTimings,
            }}
        >
            {props.children}
        </CommonDataContext.Provider>
    );
};

export default CommonDataContextProvider;
