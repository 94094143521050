import React from 'react';
import { Button, Typography } from '@material-ui/core';
import FounderImg from '../../images/founder_img_sm.jpg';
import './About.scss';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const About: React.FC = () => {
    return (
        <div className="about_root">
            <div className="hwv_body g_wfull g_whitebg"></div>
            <div className="g_mt2">
                <Typography variant="h5" align="center" gutterBottom>
                    Our Mission
                </Typography>
            </div>
            <div className="about_vision">
                <Typography
                    variant="body1"
                    align="center"
                    className="ourvision"
                    gutterBottom
                >
                    The N-Vest Partners platform connects highly skilled
                    philanthropic partners with organizations across the African
                    continent and diaspora, these organizations are focused on
                    attaining sustainable impacts in their communities through
                    their work. We leverage our global network of academic
                    institutions, corporate entities, and individuals to
                    establish relationships that are sustainable and impactful.
                </Typography>
            </div>
            <div className="g_mt2">
                <Typography variant="h5" align="center" gutterBottom>
                    Our Vision
                </Typography>
            </div>
            <div className="about_vision">
                <Typography
                    variant="body1"
                    align="center"
                    className="ourvision"
                    gutterBottom
                >
                    Founded by Emiola Taiwo, the vision of N-Vest Partners is to
                    facilitate socio-economic empowerment for Black and African
                    organizations across the African continent and diaspora. Our
                    goal with establishing skills based partnerships is to lead
                    organizations to having an even greater impact on the
                    populations they serve.
                </Typography>
            </div>
            <hr />
            {/* <DownloadBtns /> */}
            {/* <div className="about_our_team_sec">
          <div className="about_our_team_part">
            <div className="about_content">
              <Typography variant="h5">Who are we and what do we do?</Typography>
              <Typography variant="body2">Through sustainable partnerships and promoting opportunities, N-Vest Partners connects the specific needs of valuable local organizations and communities, with the time and resources of partners. N-Vest Partners provides a platform that easily enables investment in communities and organizations across the African continent and throughout the Black and African Diaspora.
</Typography>
              <div>
                <Button className="g_primary_btn" variant="contained" color="secondary">View Slideshow</Button>
              </div>
            </div>
          </div>
          <div className="about_our_team_part">
            <div className="about_image_logo">
            </div>
          </div>
        </div> */}
            <div className="g_mt2">
                <Typography variant="h5" align="center" gutterBottom>
                    Partnership Types
                </Typography>
            </div>

            <div className="about_volunteer_types">
                <div className="about_volunteer_types_sec">
                    <ImportantDevicesIcon style={{ fontSize: 60 }} />
                    <div className="g_mb1">
                        <Typography variant="h6">
                            Online Partnerships
                        </Typography>
                    </div>
                    <Typography>
                        Donate time and skill to the organizations without
                        having to be there physically.
                    </Typography>
                </div>
                <div className="about_volunteer_types_sec">
                    <LocationOnIcon style={{ fontSize: 60 }} />
                    <div className="g_mb1">
                        <Typography variant="h6">
                            In-Person Partnerships
                        </Typography>
                    </div>
                    <Typography>
                        Travel directly to meet the organization’s amazing team
                        and stakeholders in person.{' '}
                    </Typography>
                </div>
            </div>
            <div className="about_vision g_mt2">
                <Typography variant="caption" display="block" align="center">
                    *For an effective use of partners' financial resources, we
                    encourage partnerships that are local.
                </Typography>
            </div>
            <div className="about_vision g_mt2">
                <Typography>
                    To ensure seamless and effective partnerships we have
                    designed a model that promotes connections based on the
                    skills that our partners are already utilizing in their
                    professional lives. We want our partners to be excited about
                    making an impact and our organizations to see the progress
                    in their work. To achieve this, we ensure to vet our
                    partners to ensure that they have the skills needed.
                    <br />
                    <br />
                    As you browse through available projects, you will see
                    opportunities listed that are at minimum, 1 hour and maximum
                    of a 2 year commitment. This allows for quick and simple
                    projects and also more advanced projects, without creating
                    dependency. Success is achieved when our organizations are
                    in a better place at the conclusion of the partnership than
                    they were at the beginning. Through the duration of
                    partnerships, N-Vest Partners, and our Programming Liaisons,
                    will continue to stay involved; facilitating and providing
                    support.
                </Typography>
            </div>
            <hr />
            <div className="g_mt2">
                <Typography variant="h5" align="center" gutterBottom>
                    Stakeholder Types
                </Typography>
            </div>
            <div className="about_vision g_mt2">
                <Typography>
                    <ol type="1">
                        <li>
                            <b>Organizations</b> - Black and African
                            organizations across the African continent and
                            Diaspora.
                        </li>
                        <li>
                            <b>Individual Partners</b> - Skilled professionals,
                            seeking to commit to projects solo. Their
                            involvement is not tied to the facilitation of any
                            academic or corporate institutions.
                        </li>
                        <li>
                            <b>Academic Institutions</b> - Through the
                            facilitation of a group admin, these are skilled
                            students and staff who are seeking to commit to
                            projects as part of their institutions collective
                            goals.
                        </li>
                        <li>
                            <b>Corporate Partners</b> - Through the facilitation
                            of a group admin, these are skilled employees who
                            are seeking to commit to projects as part of their
                            companies collective goals.
                        </li>
                    </ol>
                </Typography>
            </div>
            <hr />
            <div className="g_mt2">
                <div className="founderImgWrap g_mb2">
                    <img src={FounderImg} alt="hero img" className="teamImg" />
                </div>
                <Typography variant="h5" align="center" gutterBottom>
                    Our Founder's Story
                </Typography>
            </div>
            <div className="about_vision g_mt2">
                <Typography>
                    N-Vest Partners is inspired by my love for Black and African
                    communities, and desire to invest in sustainable ways.
                    During my graduate studies, I took the opportunity to use my
                    final research to research the concept of “Brain Gain” and
                    focus on the impacts of intentionally investing in local
                    groups. I was encouraged to see that the concept of
                    investment through volunteerism was something that the vast
                    majority of my 200 plus respondents were interested in.
                    However, the common theme presented in the responses was
                    that respondents were not willing to create opportunities to
                    do so but were willing to use their time, skills and
                    resources to support credible organizations. I continue to
                    be inspired by current impacts that local organizations have
                    on their communities. I have built a way that, rather than
                    fighting for resources for new initiatives, our work will be
                    committed to N-Vesting in the important work that is already
                    taking place, allowing for local organizations to have
                    stronger, larger, and long-lasting Impacts.
                    <br />
                    <br />
                    N-Vest Partners is the platform that allows partners to
                    invest in local groups that have great impacts in their
                    communities. I believe that there are several great groups
                    on ground doing great work, however there are multiple
                    barriers that restrict their level of impact. My desire,
                    through N-Vest Partners, is to eliminate barriers to
                    volunteerism , within local communities and internationally,
                    in addition to promoting investment for social impact groups
                    across the African Continent and Diaspora.
                </Typography>
            </div>
            <hr />

            <div className="about_volunteer_types">
                <div className="about_volunteer_types_sec">
                    {/* <ImportantDevicesIcon style={{ fontSize: 60 }}/> */}

                    <div className="g_mt2">
                        <Typography variant="h5" align="center" gutterBottom>
                            Meet the Team
                        </Typography>
                    </div>
                    <div className="about_volunteer_types">
                        <div className="about_volunteer_types_sec">
                            {/* <ImportantDevicesIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                    Tiffany Odoh
                                </Typography>
                            </div>
                            <Typography>
                                Lead Developer/ Back End Technology Architect
                            </Typography>
                        </div>
                        <div className="about_volunteer_types_sec">
                            {/* <LocationOnIcon style={{ fontSize: 60 }}/> */}
                            <div>
                                <Typography variant="h6">
                                    Odunayo Obajuluwa
                                </Typography>
                            </div>
                            <Typography>
                                Lead Developer: Front End Design and Developer
                            </Typography>
                        </div>
                    </div>
                    <div className="about_vision g_mt2">
                        <Typography>
                            We are growing our team! If you are interested in
                            making a longer term, strategic impact, please reach
                            out to us as nvestpartnershq@gmail.com. In the weeks
                            and months to come, we will be growing our
                            operations team and need donations, sponsorships,
                            and capital investment to allow us to scale our
                            operations.
                        </Typography>
                    </div>
                    <div className="about_our_team_part about_vision g_mt2">
                        <div className="about_content">
                            <Typography variant="h5">Learn More</Typography>
                            <Typography variant="body2">
                                There is more to our story. To find frequently
                                asked questions or comments look through our FAQ
                                page, or contact us for more details.
                            </Typography>
                            <div className="g_row">
                                <div className="g_mr1">
                                    <Button
                                        className="g_primary_btn"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        View Slideshow
                                    </Button>
                                </div>
                                <div className="g_mr1">
                                    <Button variant="outlined" color="default">
                                        Contact Us
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
