import React, { FunctionComponent } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type LocationProps = {
    selectProps: any;
};
const Location: FunctionComponent<LocationProps> = (props) => {
    const { selectProps } = props;

    return (
        <div style={{ width: '100%' }}>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                selectProps={selectProps}
                onLoadFailed={(error) => console.log(error)}
            />
        </div>
    );
};

export default Location;
