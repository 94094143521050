import {
    Badge,
    Button,
    Dialog,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SocialMedias } from 'const';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { SocialMediaType } from 'types';
import SocialIcon from './SocialIcon';
import { useSnackbar } from 'notistack';

interface EditSocialsPopupProps {
    isOpen: boolean;
    initialValue: Array<SocialMediaType>;
    onUpdate?: (a: Array<SocialMediaType>) => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        // width: '100%',
        background: 'white',
        padding: '1rem',
        boxSizing: 'border-box',
        margin: '1rem auto',
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 700,
        marginTop: '1rem',
        marginBottom: '.4rem',
    },
    buttons: {
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
}));

const EditSocialsPopup = (props: EditSocialsPopupProps) => {
    const classes = useStyles();

    const [selectValue, setSelectValue] = useState(' ');
    const [url, setUrl] = useState('');
    const [name, setName] = useState('');

    const [value, setValue] = useState<Array<SocialMediaType>>(
        props.initialValue,
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (props.isOpen) {
            setValue(props.initialValue);
        }
    }, [props.isOpen, props.initialValue]);

    const addIcon = () => {
        if (selectValue === ' ') {
            enqueueSnackbar('Please select a type!', {
                variant: 'error',
            });
        } else if (selectValue === 'other' && name.trim() === '') {
            enqueueSnackbar('Please enter a name!', {
                variant: 'error',
            });
        } else if (url.trim() === '') {
            enqueueSnackbar('Please enter a url!', {
                variant: 'error',
            });
        } else {
            if (selectValue === 'other') {
                setValue((pre) => [...pre, { type: name, url: url }]);
            } else {
                setValue([...value, { type: selectValue, url }]);
            }
            setSelectValue(' ');
            setUrl('');
            setName('');
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <div className={classes.root}>
                <Typography className={classes.title}>Type</Typography>
                <Select
                    variant="outlined"
                    value={selectValue}
                    onChange={(e: ChangeEvent<any>) =>
                        setSelectValue(e.target.value)
                    }
                >
                    <MenuItem value={' '} disabled>
                        Select Type
                    </MenuItem>
                    <MenuItem value={SocialMedias.FACEBOOK}>Facebook</MenuItem>
                    <MenuItem value={SocialMedias.TWITTER}>Twitter</MenuItem>
                    <MenuItem value={SocialMedias.LINKEDIN}>LinkedIn</MenuItem>
                    <MenuItem value={SocialMedias.INSTAGRAM}>
                        Instagram
                    </MenuItem>
                    <MenuItem value={SocialMedias.YOUTUBE}>Youtube</MenuItem>
                    <MenuItem value={SocialMedias.ORGANIZATION_WEBSITE}>
                        Organization Website
                    </MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                </Select>
                {selectValue === 'other' && (
                    <>
                        <Typography className={classes.title}>Name</Typography>
                        <TextField
                            variant="outlined"
                            value={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setName(e.target.value)
                            }
                        />
                    </>
                )}
                <Typography className={classes.title}>Url</Typography>
                <TextField
                    variant="outlined"
                    value={url}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setUrl(e.target.value)
                    }
                />
                <div className={`${classes.row} ${classes.buttons}`}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={addIcon}
                    >
                        Add
                    </Button>
                </div>
                <div>
                    {value?.length &&
                        value.map((item: SocialMediaType, index: number) => (
                            <Badge
                                overlap="circle"
                                badgeContent={<Close />}
                                onClick={() => {
                                    setValue((pre) => {
                                        let temp = [...pre];
                                        temp.splice(index, 1);
                                        return temp;
                                    });
                                }}
                                key={index}
                            >
                                <SocialIcon
                                    url={item.url}
                                    name={item.type}
                                    isBlack
                                    isDisabled
                                />
                            </Badge>
                        ))}
                </div>
                <div className={`${classes.row} ${classes.buttons}`}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color={'primary'}
                        onClick={props.onClose}
                    >
                        close
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color={'primary'}
                        onClick={() => {
                            props.onUpdate?.(value);
                            props.onClose();
                        }}
                    >
                        update
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditSocialsPopup;
