import {
    ACCOUNT_TYPES,
    FIREBASE_APIKEY,
    FIREBASE_APPID,
    FIREBASE_AUTHDOMAIN,
    FIREBASE_DATABASEURL,
    FIREBASE_MESSAGINGSENDERID,
    FIREBASE_PROJECTID,
    FIREBASE_STORAGEBUCKET,
} from '../const';
import { DocumentData } from '@firebase/firestore-types';
import dayjs from 'dayjs';
import {
    prepareAvatar,
    prepareCoverImage,
    prepareProjectImage,
    waitForUploadTaskCompletion,
} from './ImageUtils';
import { nanoid } from 'nanoid';
import {
    ApprovalType,
    ChatType,
    NetworkResponse,
    OrganizationType,
    ProjectType,
    VolunteerProjectType,
    SocialMediaType,
    TeamMember,
    UserType,
} from 'types';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/messaging';
import { BroadCastType } from 'types';
import { updatePassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: FIREBASE_APIKEY,
    authDomain: FIREBASE_AUTHDOMAIN,
    databaseURL: FIREBASE_DATABASEURL,
    projectId: FIREBASE_PROJECTID,
    storageBucket: FIREBASE_STORAGEBUCKET,
    messagingSenderId: FIREBASE_MESSAGINGSENDERID,
    appId: FIREBASE_APPID,
};

class Firebase {
    auth: firebase.auth.Auth;
    db: firebase.firestore.Firestore;
    storage: firebase.storage.Storage;
    functions: firebase.functions.Functions;
    messaging: firebase.messaging.Messaging;

    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();
        this.messaging = firebase.messaging();
    }

    async login(email: string, password: string) {
        try {
            const userCredential = await this.auth.signInWithEmailAndPassword(
                email,
                password,
            );
            if (userCredential.user) {
                const userDetails = await this.getUserData();
                if (!userDetails.isApproved) {
                    this.logout();
                    throw new Error(
                        'Your account is being reviewed. Please wait for it to be approved',
                    );
                }
            }
        } catch (err: any) {
            throw new Error(
                err.code
                    ? 'Error signing in with password and email!'
                    : err.message,
            );
        }
    }

    async getUserData() {
        let returnData: any = (
            await this.db
                .collection('users')
                .doc(this.auth.currentUser?.uid)
                .get()
        ).data();
        return returnData;
    }

    async getUserDataById(userId: string) {
        let returnData: any = (
            await this.db.collection('users').doc(userId).get()
        ).data();
        return returnData;
    }

    async getUserDataByEmail(email: string) {
        let returnData: any = await this.db
            .collection('users')
            .where('email', '==', email)
            .get();
        return returnData;
    }

    async getUserType() {
        let returnData: any = (
            await this.db
                .collection('users')
                .doc(this.auth.currentUser?.uid)
                .get()
        ).data();
        return returnData.account_type;
    }

    async logout() {
        return await this.auth.signOut();
    }

    isInitialized() {
        return new Promise((resolve) => this.auth.onAuthStateChanged(resolve));
    }

    isAuthenticated() {
        return this.auth.currentUser !== null;
    }

    resetPassword(email: string) {
        return this.auth.sendPasswordResetEmail(email);
    }

    generatePassword(length: number) {
        const charset =
            'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?/';
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }

        return password;
    }

    //TODO: Need to change this function in future to support new password as parameter
    async changePassword(newPassword: string) {
        await updatePassword(this.auth.currentUser!, newPassword);
        await this.logout();
    }

    getCurrentUsername() {
        return this.auth.currentUser?.displayName ?? '';
    }

    getCurrentUserEmail() {
        return this.auth.currentUser && this.auth.currentUser.email;
    }

    getCurrentUserId() {
        return this.auth.currentUser && this.auth.currentUser.uid;
    }

    async register(email: string, password: string) {
        return await this.auth.createUserWithEmailAndPassword(email, password);
    }

    registerUser(email: string, password: string, callback: any) {
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((userData) => {
                callback({
                    status: 'ok',
                    user: userData.user,
                });
            })
            .catch((error) => {
                callback({
                    status: error.code,
                });
            });
    }

    async uploadFileToStorage(uid: string, step2File: any) {
        debugger;
        if (step2File) {
            let imageRef = firebase
                .storage()
                .ref()
                .child(`applications/${uid}/${step2File.name}`);

            debugger;
            await imageRef.put(step2File);
            let downloadUrl = await imageRef.getDownloadURL();
            // console.log("URL ", downloadUrl);
            return downloadUrl;
        }
    }

    async uploadUserDataToFirestore(
        uid: string,
        step1Data: any,
        step2Data: any,
        step3Data: any,
        step3DataReferences: any,
        fileUrl: any,
    ) {
        try {
            let batch = this.db.batch();
            if (step1Data.application_type === ACCOUNT_TYPES[0]) {
                let dateOfBirth = `${dayjs(step1Data.date_time).format(
                    'YYYY-MM-DD',
                )}`;
                batch.set(
                    this.db.collection('users').doc(uid),
                    {
                        // name: step1Data.first_name + " " + step1Data.last_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        avatar: 'https://firebasestorage.googleapis.com/v0/b/nvest-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd',
                        company: step1Data.company_name,
                        email: step1Data.email,
                        // password: step1Data.password,
                        account_type: step1Data.application_type,
                        skills: [],
                        work_type: 3,
                        timing: 2,
                        projects: [],
                        isApproved: false,
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
                batch.set(
                    this.db.collection('applications').doc(uid),
                    {
                        application_type: step1Data.application_type,
                        email: step1Data.email,
                        company_name: step1Data.company_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        date_of_birth: dateOfBirth,
                        gender: step1Data.gender,
                        phone_number: step1Data.phone_number,
                        profession: step2Data,
                        references: step3DataReferences,
                        statement: step3Data,
                        attachment: fileUrl,
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
            } else if (
                step1Data.application_type === ACCOUNT_TYPES[1] ||
                step1Data.application_type === ACCOUNT_TYPES[2]
            ) {
                batch.set(
                    this.db.collection('users').doc(uid),
                    {
                        // name: step1Data.first_name + " " + step1Data.last_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        avatar: 'https://firebasestorage.googleapis.com/v0/b/nvest-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd',
                        company: step1Data.company_name,
                        email: step1Data.email,
                        // password: step1Data.password,
                        account_type: step1Data.application_type,
                        skills: [],
                        work_type: 3,
                        timing: 2,
                        projects: [],
                        isApproved: false,
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
                batch.set(
                    this.db.collection('applications').doc(uid),
                    {
                        application_type: step1Data.application_type,
                        email: step1Data.email,
                        company_name: step1Data.company_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        gender: step1Data.gender,
                        phone_number: step1Data.phone_number,
                        profession: step2Data,
                        references: step3DataReferences,
                        statement: step3Data,
                        attachment: fileUrl || '',
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
            } else {
                let organization_id: string = step1Data.company_name;
                organization_id = organization_id.replace('/', '-');
                organization_id = organization_id.replace('\\', '-');
                organization_id = organization_id.replace(/\s/g, '-');
                organization_id = organization_id.replace('...', '-');
                organization_id = organization_id.replace('..', '-');
                organization_id = organization_id.replace('.', '-');
                organization_id = organization_id + '-' + nanoid(4);
                organization_id = organization_id.toLowerCase();
                batch.set(
                    this.db.collection('users').doc(uid),
                    {
                        // name: step1Data.first_name + " " + step1Data.last_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        avatar: 'https://firebasestorage.googleapis.com/v0/b/nvest-dev.appspot.com/o/avatars%2Favatar2.png?alt=media&token=9fb4a4aa-79ab-43d9-9c11-dc913706effd',
                        company: step1Data.company_name,
                        email: step1Data.email,
                        // password: step1Data.password,
                        account_type: step1Data.application_type,
                        organization_id: organization_id,
                        isApproved: false,
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
                batch.set(
                    this.db.collection('organizations').doc(organization_id),
                    {
                        uid: uid,
                        company: step1Data.company_name,
                        city: step1Data.city,
                        country: step1Data.country,
                        vision: '',
                        mission: '',
                        success_stories: '',
                        financials: '',
                        our_team: '',
                        socials: [],
                        projects: [],
                        reviewes: [],
                        ourMembers: [],
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
                batch.set(
                    this.db.collection('applications').doc(uid),
                    {
                        application_type: step1Data.application_type,
                        email: step1Data.email,
                        company_name: step1Data.company_name,
                        first_name: step1Data.first_name,
                        last_name: step1Data.last_name,
                        gender: step1Data.gender,
                        phone_number: step1Data.phone_number,
                        profession: step2Data,
                        references: step3DataReferences,
                        statement: step3Data,
                        attachment: fileUrl,
                        created_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true },
                );
            }
            await batch.commit();
        } catch (err) {
            return {
                status: 'error',
                payload: err,
            };
        }
    }

    async getApprovedCompanyNames() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('approved_companies')
            .get();
        let data = await returnData.data();
        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getVolunteerLocations() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('volunteer-location')
            .get();
        let data = await returnData.data();
        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getVolunteerSkills() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('volunteer-skills')
            .get();
        let data = await returnData.data();
        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getVolunteerTimings() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('volunteer-timings')
            .get();
        let data = await returnData.data();

        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getAllCauses() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('causes')
            .get();
        let data: { list: string[] } = await returnData.data();

        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getAllCategories() {
        let returnData: DocumentData = await this.db
            .collection('public')
            .doc('categories')
            .get();
        let data: { list: string[] } = await returnData.data();

        if (data && data.list) {
            return data.list;
        } else {
            return [];
        }
    }

    async getOrganizationDataById(id: string | undefined) {
        if (id) {
            let returnData: DocumentData | null = await this.db
                .collection('organizations')
                .doc(id)
                .get();
            if (returnData) {
                let data = await returnData.data();
                if (data) {
                    data.id = id;
                    return data;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    async getOrganizationProjectsDataByUid(organization_uid: string) {
        try {
            let snapshot = await this.db
                .collection('projects')
                .where('organization_id', '==', organization_uid)
                .get();
            if (snapshot) {
                let returnData: Array<ProjectType> = [];
                snapshot.forEach((doc) => {
                    let temp = doc.data();
                    temp.id = doc.id;
                    returnData.push(temp as ProjectType);
                });
                return {
                    status: 'ok',
                    payload: returnData,
                };
            } else {
                return {
                    status: 'error',
                    payload: 'unable to fetch data',
                };
            }
        } catch (e: any) {
            return {
                status: 'error',
                payload: e,
            };
        }
    }
    async getOrganizationDataByUid(organization_uid: string) {
        try {
            let snapshot = await this.db
                .collection('organizations')
                .where('uid', '==', organization_uid)
                .get();
            if (snapshot) {
                let returnData: Array<OrganizationType> = [];
                snapshot.forEach((doc) => {
                    let temp = doc.data();
                    temp.id = doc.id;
                    returnData.push(temp as OrganizationType);
                });
                return {
                    status: 'ok',
                    payload: returnData[0],
                };
            } else {
                return {
                    status: 'error',
                    payload: 'unable to fetch data',
                };
            }
        } catch (e: any) {
            return {
                status: 'error',
                payload: e,
            };
        }
    }

    async getNews() {
        let querySnapshot = await this.db.collection('newsfeed').get();
        let list: Array<any> = [];
        querySnapshot.forEach(function (doc) {
            let data = doc.data();
            data.poster_id = doc.id;
            list.push(data);
        });

        return list;
    }

    /*
	Projects Context
  */

    async getVolunteerProjects(volunteerId: string) {
        let returnData = [];
        let volunteer_id = volunteerId || this.auth.currentUser?.uid;
        let userData = (
            await this.db.collection('users').doc(volunteer_id).get()
        ).data();
        if (userData && userData.projects) {
            let projects = userData?.projects;
            for (let i = 0; i < projects.length; i++) {
                let projectData: any = (
                    await this.db.collection('projects').doc(projects[i]).get()
                ).data();
                if (projectData && projects[i]) {
                    projectData.id = projects[i];
                    returnData.push(projectData);
                }
            }
            return returnData;
        } else {
            return [];
        }
    }

    async getVolunteerProjectsByOrganization(organizationId: string) {
        let snapshot = await this.db
            .collection('projects')
            .where('organization_id', '==', organizationId)
            .get();
        if (snapshot.docs.length) {
            let volunteerIds: string[] = [];
            snapshot.forEach((doc) => {
                let project = doc.data() as ProjectType;
                volunteerIds = volunteerIds.concat([
                    ...project.team_member_ids,
                ]);
            });
            return {
                status: 'ok',
                payload: _uniq(volunteerIds),
            };
        } else {
            return {
                status: 'error',
                payload: "It seems like you don't have any volunteers",
            };
        }
    }

    async getOrganizationProjects() {
        if (this.auth.currentUser?.uid) {
            let organization_uid = this.auth.currentUser?.uid;
            try {
                let dataSnapshot = await this.db
                    .collection('projects')
                    .where('organization_id', '==', organization_uid)
                    .get();
                let returnData: Array<ProjectType> = [];
                dataSnapshot.forEach((doc) => {
                    const data = {
                        ...doc.data(),
                        documentId: doc.id,
                    };
                    returnData.push(data as ProjectType);
                });
                return {
                    status: 'ok',
                    payload: returnData,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e,
                };
            }
        } else {
            return null;
        }
    }

    async getFeaturedOrganizations() {
        try {
            let snapshot = await this.db
                .collection('public')
                .doc('featured_organizations')
                .get();
            let list: Array<string> = snapshot.data()?.list;
            if (list && list.length > 0) {
                let returnData: Array<OrganizationType> = [];
                let snapshot2 = await this.db
                    .collection('organizations')
                    .where(
                        firebase.firestore.FieldPath.documentId(),
                        'in',
                        list,
                    )
                    .get();
                if (snapshot2 && !snapshot2.empty) {
                    snapshot2.forEach((doc) => {
                        let temp = doc.data();
                        temp.id = doc.id;
                        returnData.push(temp as OrganizationType);
                    });
                    return {
                        status: 'ok',
                        payload: returnData,
                    };
                } else {
                    return {
                        status: 'ok',
                        payload: [],
                    };
                }
            } else {
                return {
                    status: 'ok',
                    payload: [],
                };
            }
        } catch (e: any) {
            return {
                status: 'error',
                payload: e,
            };
        }
    }

    async getFeaturedProjects() {
        try {
            let snapshot = await this.db
                .collection('public')
                .doc('featured_projects')
                .get();
            let list: Array<string> = snapshot.data()?.list;
            if (list && list.length > 0) {
                let returnData: Array<ProjectType> = [];
                let snapshot2 = await this.db
                    .collection('projects')
                    .where(
                        firebase.firestore.FieldPath.documentId(),
                        'in',
                        list,
                    )
                    .get();
                if (snapshot2 && !snapshot2.empty) {
                    snapshot2.forEach((doc) => {
                        let temp = doc.data();
                        temp.id = doc.id;
                        returnData.push(temp as ProjectType);
                    });
                    return {
                        status: 'ok',
                        payload: returnData,
                    };
                } else {
                    return {
                        status: 'ok',
                        payload: [],
                    };
                }
            } else {
                return {
                    status: 'ok',
                    payload: [],
                };
            }
        } catch (e: any) {
            return {
                status: 'error',
                payload: e,
            };
        }
    }

    /************************
     * USER PROFILE UPDATE
     ************************/

    async updateUsername(firstName: string, lastName: string) {
        if (this.auth.currentUser) {
            await this.db
                .collection('users')
                .doc(this.auth.currentUser.uid)
                .set(
                    {
                        first_name: firstName,
                        last_name: lastName,
                    },
                    { merge: true },
                );
        }
    }

    async updateCompany(data: Record<string, unknown>) {
        if (this.auth.currentUser) {
            await this.db
                .collection('users')
                .doc(this.auth.currentUser.uid)
                .set(data, { merge: true });
        }
    }

    async updateAboutMe(aboutMe: string) {
        if (this.auth.currentUser) {
            await this.db
                .collection('users')
                .doc(this.auth.currentUser.uid)
                .set({ about_me: aboutMe }, { merge: true });
        }
    }

    async updateEmail(newEmail: string, password: string) {
        if (this.auth.currentUser) {
            const credential = firebase.auth.EmailAuthProvider.credential(
                this.auth.currentUser.email ? this.auth.currentUser.email : '',
                password,
            );
            try {
                await this.auth.currentUser.reauthenticateWithCredential(
                    credential,
                );
                await this.auth.currentUser.updateEmail(newEmail);
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            email: newEmail,
                        },
                        { merge: true },
                    );
                await this.db
                    .collection('applications')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            email: newEmail,
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e.code;
            }
            // await this.auth.currentUser.updateEmail(newEmail)
        }
        return null;
    }

    async updatePassword(newPassword: string, oldPassword: string) {
        if (this.auth.currentUser) {
            const credential = firebase.auth.EmailAuthProvider.credential(
                this.auth.currentUser.email ? this.auth.currentUser.email : '',
                oldPassword,
            );
            try {
                await this.auth.currentUser.reauthenticateWithCredential(
                    credential,
                );
                await this.auth.currentUser.updatePassword(newPassword);
                // await this.db
                //     .collection('users')
                //     .doc(this.auth.currentUser.uid)
                //     .set(
                //         {
                //             password: newPassword,
                //         },
                //         { merge: true },
                //     );
            } catch (e: any) {
                return e.code;
            }
            // await this.auth.currentUser.updateEmail(newEmail)
        }
        return null;
    }

    async updateWorkType(newId: number) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            work_type: newId,
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e.code;
            }
        }
        return null;
    }

    async updateWorkTiming(newId: number) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            timing: newId,
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e.code;
            }
        }
        return null;
    }

    async addSkill(skill: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            skills: firebase.firestore.FieldValue.arrayUnion(
                                skill,
                            ),
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e.code;
            }
        }
        return null;
    }

    async removeSkill(skill: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            skills: firebase.firestore.FieldValue.arrayRemove(
                                skill,
                            ),
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e.code;
            }
        }
        return null;
    }

    async updateAvatar(image: File) {
        if (this.auth.currentUser) {
            let imageName = this.auth.currentUser.uid + '.jpg';

            let compressedFile: any = await prepareAvatar(image);

            // console.log(compressedFile);

            let uploadTask = this.storage
                .ref(`avatars/${imageName}`)
                .put(compressedFile);
            try {
                let downloadUrl = await waitForUploadTaskCompletion(uploadTask);
                await this.db
                    .collection('users')
                    .doc(this.auth.currentUser.uid)
                    .set(
                        {
                            avatar: downloadUrl,
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e;
            }
        }
        return null;
    }

    /************************
     *END USER PROFILE UPDATE
     ************************/

    /*********************************
     *   ORGANIZATION PROFILE UPDATE
     *********************************/

    async createProjectImage(image: File) {
        if (this.auth.currentUser) {
            let imageName = nanoid(16) + '.jpg';

            let compressedFile: any = await prepareProjectImage(image);

            let uploadTask = this.storage
                .ref(`projects/${imageName}`)
                .put(compressedFile);
            try {
                let downloadUrl = await waitForUploadTaskCompletion(uploadTask);
                return { status: 'ok', payload: downloadUrl };
            } catch (e: any) {
                return { status: 'error', payload: e };
            }
        }
        return null;
    }
    async updateProjectImage(image: File) {
        if (this.auth.currentUser) {
            let imageName = nanoid(16) + '.jpg';

            let compressedFile: any = await prepareProjectImage(image);

            let uploadTask = this.storage
                .ref(`projects/${imageName}`)
                .put(compressedFile);
            try {
                let downloadUrl = await waitForUploadTaskCompletion(uploadTask);
                return { status: 'ok', payload: downloadUrl };
            } catch (e: any) {
                return { status: 'error', payload: e };
            }
        }
        return null;
    }

    async createProject(project: ProjectType) {
        if (this.auth.currentUser) {
            let createProject = this.functions.httpsCallable('createProject');
            try {
                let createProjectResult = await createProject(project);
                return createProjectResult.data;
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e,
                };
            }
        }
        return null;
    }
    async createVolunteerProject(
        volunteerProject: VolunteerProjectType,
        userId: string | undefined,
    ) {
        console.log('Inside create volunteer project');
        volunteerProject.id = volunteerProject.volunteer_id;
        if (!this.auth.currentUser) {
            return {
                status: 'error',
                message: 'User is not authenticated.',
            };
        }

        try {
            // Add a new document to the 'volunteerProjects' collection
            const docRef = await this.db
                .collection('volunteerProjects')
                .add(volunteerProject);

            console.log(`New volunteer project added with ID: ${docRef.id}`);
            return {
                status: 'success',
                message: 'Volunteer project added successfully.',
                data: {
                    // id: docRef.id,
                    ...volunteerProject,
                },
            };
        } catch (error) {
            console.error('Error adding volunteer project:', error);
            return {
                status: 'error',
                message: 'Failed to add the volunteer project.',
                payload: error,
            };
        }
        // if (this.auth.currentUser) {
        //     let createVolunteerProject = this.functions.httpsCallable('createVolunteerProject');
        //     try {
        //         console.log("createVolunteerProject",createVolunteerProject)
        //         let createVolunteerProjectResult = await createVolunteerProject(volunteerProject);
        //         console.log("createVolunteerProjectResult",createVolunteerProjectResult)
        //         return createVolunteerProjectResult.data;
        //     } catch (e: any) {
        //         return {
        //             status: 'error',
        //             payload: e,
        //         };
        //     }
        // }
        // return null;
    }
    async updateVolunteerProject(volunteerProject: VolunteerProjectType) {
        if (!volunteerProject?.documentId) {
            console.error('Invalid project documentId:', volunteerProject);
            return { status: 'error', message: 'Invalid document ID' };
        }
        if (this.auth.currentUser) {
            try {
                console.log('Updating project:', volunteerProject);
                await this.db
                    .collection('volunteerProjects')
                    .doc(volunteerProject?.documentId)
                    .update({
                        id: volunteerProject.id,
                        partnershipOpportunity:
                            volunteerProject.partnershipOpportunity,
                        description: volunteerProject.description,
                        schedule_start: volunteerProject.schedule_start,
                        schedule_end: volunteerProject.schedule_end,
                        team_members: volunteerProject.team_members,
                        team_member_ids: volunteerProject.team_member_ids,
                        map_url: volunteerProject.map_url,
                        skills: volunteerProject.skills,
                        activity_type: volunteerProject.activity_type,
                        activity_duration_per_week:
                            volunteerProject.activity_duration_per_week,
                        causes: volunteerProject.causes,
                        categories: volunteerProject.categories,
                        location: volunteerProject.location,
                        volunteer_id: volunteerProject.volunteer_id,
                    });
                return { status: 'ok' };
            } catch (error) {
                console.error('Error updating project:', error);
                return {
                    status: 'error',
                    message: 'Failed to update project',
                    error,
                };
            }
        }
        return { status: 'error', message: 'User not authenticated' };
    }
    async updateProject(project: ProjectType) {
        if (this.auth.currentUser) {
            try {
                await this.db.collection('projects').doc(project.id).update({
                    id: project.id,
                    name: project.name,
                    total_partners: project.total_partners,
                    committed_partners: project.committed_partners,
                    description: project.description,
                    group_name: project.group_name,
                    schedule_start: project.schedule_start,
                    schedule_end: project.schedule_end,
                    project_deadline: project.project_deadline,
                    organization_id: project.organization_id,
                    team_members: project.team_members,
                    team_member_ids: project.team_member_ids,
                    map_url: project.map_url,
                    image_url: project.image_url,
                    skills: project.skills,
                    requirements: project.requirements,
                    activity_type: project.activity_type,
                    activity_duration_per_week:
                        project.activity_duration_per_week,
                    causes: project.causes,
                    categories: project.categories,
                    location: project.location,
                });
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateVision(vision: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            vision: vision,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }
    async updateOrganization(
        data: Record<string, unknown>,
        organization_id: string,
    ) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(data, { merge: true });
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }
    async updateImpact(impact: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            impact: impact,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }
    async updateOverview(overview: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            overview: overview,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateMission(mission: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            mission: mission,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateSuccessStories(
        successStories: string,
        organization_id: string,
    ) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            success_stories: successStories,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateFinancials(financials: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            financials: financials,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateOurTeam(ourTeam: string, organization_id: string) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            our_team: ourTeam,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async updateSocials(
        socials: Array<SocialMediaType>,
        organization_id: string,
    ) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organizations')
                    .doc(organization_id)
                    .set(
                        {
                            socials: socials,
                        },
                        { merge: true },
                    );
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    /*********************************
     * END ORGANIZATION PROFILE UPDATE
     *********************************/

    /**********************
     *  PROFILE GENERAL
     **********************/

    async getOtherUserData(userId: string) {
        if (this.auth.currentUser) {
            try {
                let docSnapshot = await this.db
                    .collection('users')
                    .doc(userId)
                    .get();
                if (docSnapshot.exists) {
                    let data = docSnapshot.data();
                    let userName = '';
                    let userAvatar = '';
                    if (data) {
                        userAvatar = data.avatar;
                        if (data.account_type === ACCOUNT_TYPES[3]) {
                            userName = data.company;
                        } else {
                            userName = data.first_name + ' ' + data.last_name;
                        }
                    }
                    return {
                        status: 'ok',
                        payload: { userName: userName, userAvatar: userAvatar },
                    };
                } else {
                    return { status: 'error' };
                }
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async createReply(reply: string, position: number, chatId: string) {
        if (this.auth.currentUser) {
            try {
                const replyId = nanoid(16);
                let chatDoc = this.db.collection('chat').doc(chatId);
                const replyDoc = chatDoc.collection('replies').doc(replyId);
                const isPrevChatExists = (
                    await this.db.collection('chat').doc(chatId).get()
                ).exists;
                if (!isPrevChatExists) {
                    await this.db
                        .collection('chat')
                        .doc(chatId)
                        .set({
                            created_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            created_by: this.auth.currentUser?.uid ?? '',
                            is_read: false,
                            last_read: [replyId],
                            participants: [this.auth.currentUser.uid, chatId],
                            deleted_until_id: [],
                            last_reply: replyId,
                            updated_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                        });

                    //chatDoc = this.db.collection('chat').doc(chatId);
                }

                await this.db.runTransaction(async (t) => {
                    let chat = (await t.get(chatDoc)).data() as ChatType;
                    chat.last_read[position] = replyId;
                    chat.last_reply = replyId;
                    chat.updated_at =
                        firebase.firestore.FieldValue.serverTimestamp();
                    if (reply) {
                        t.set(chatDoc, { ...chat }, { merge: true });
                        t.set(replyDoc, {
                            created_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            updated_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            created_by: this.auth.currentUser?.uid ?? '',
                            is_read: false,
                            text: reply,
                        });
                    } else {
                        t.set(chatDoc, { ...chat }, { merge: true });
                        t.set(replyDoc, {
                            created_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            updated_at:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            created_by: this.auth.currentUser?.uid ?? '',
                            is_read: false,
                        });
                    }
                });
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async deleteMessage(messageId: string) {
        if (this.auth.currentUser) {
            try {
                const repliesCollection = await this.db
                    .collection('chat')
                    .doc(messageId)
                    .collection('replies');

                // Step 2: Get all documents in the "replies" collection
                const repliesQuerySnapshot = await repliesCollection.get();

                // Step 3: Delete each document in the "replies" collection
                const deletePromises: any[] = [];
                repliesQuerySnapshot.forEach((doc) => {
                    const deletePromise = repliesCollection
                        .doc(doc.id)
                        .delete();
                    deletePromises.push(deletePromise);
                });
                // Step 4: Wait for all delete operations to complete
                await Promise.all(deletePromises);

                // Step 5: After all replies are deleted, you can delete the main document
                const mainDocumentDelete = this.db
                    .collection('chat')
                    .doc(messageId)
                    .delete();

                // Wait for the main document deletion to complete
                await mainDocumentDelete;
                return {
                    payload: 'Message successfully deleted ',
                    status: 200,
                };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'user not authorized' };
    }

    async updateLastRead(chatId: string, position: number) {
        if (this.auth.currentUser) {
            try {
                const chatDoc = this.db.collection('chat').doc(chatId);

                await this.db.runTransaction(async (t) => {
                    let chat = (await t.get(chatDoc)).data() as ChatType;

                    chat.last_read[position] = chat.last_reply;
                    // chat.updated_at = serverTimestamp;
                    t.set(chatDoc, { ...chat }, { merge: true });
                });
                return { status: 'ok' };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error' };
    }

    async sendEmail(data: {
        to: string;
        html: string;
        text?: string;
        subject: string;
        cc?: string;
    }) {
        const createSendEmail = this.functions.httpsCallable('sendEmail');
        try {
            const returnedValues = await createSendEmail(data);
            return returnedValues;
        } catch (error) {
            console.log('ERROR OCCURED WHEN SENDING EMAIL');
        }
    }

    async createReview(title: string, body: string, organizationId: string) {
        if (this.auth.currentUser) {
            let createProject = this.functions.httpsCallable('createReview');
            try {
                let createProjectResult = await createProject({
                    title: title,
                    body: body,
                    organizationId: organizationId,
                });

                return createProjectResult.data;
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e,
                };
            }
        }
        return {
            status: 'error',
        };
    }

    /**********************
     * END PROFILE GENERAL
     **********************/

    /**********************************
     * getProjectDetailsById
     ***********************************/

    async getProjectDetailsById(id: string) {
        if (id) {
            let returnData: DocumentData | null = await this.db
                .collection('projects')
                .doc(id)
                .get();
            if (returnData) {
                let data = await returnData.data();
                if (data) {
                    data.id = id;
                    return data;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
    async getVolunteerProjectDetailsById(id: string) {
        if (id) {
            let returnData: DocumentData | null = await this.db
                .collection('volunteerProjects')
                .doc(id)
                .get();
            if (returnData) {
                let data = await returnData.data();
                if (data) {
                    // data.id = id;
                    // return data;
                    const result = {
                        ...data,
                        documentId: id,
                    };
                    console.log('data is inside api call:', result);
                    return result;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
    async getVolunteerPersonalProjects() {
        if (this.auth.currentUser?.uid) {
            let volunteer_uid = this.auth.currentUser?.uid;
            try {
                let dataSnapshot = await this.db
                    .collection('volunteerProjects')
                    .where('volunteer_id', '==', volunteer_uid)
                    .get();
                let returnData: Array<VolunteerProjectType> = [];
                dataSnapshot.forEach((doc) => {
                    const data = {
                        ...doc.data(),
                        documentId: doc.id,
                    };
                    console.log('documnetId', data);
                    returnData.push(data as VolunteerProjectType);
                });
                return {
                    status: 'ok',
                    payload: returnData,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e,
                };
            }
        } else {
            return null;
        }
    }
    async getAllVolunteerPersonalProjects() {
        if (this.auth.currentUser?.uid) {
            try {
                let dataSnapshot = await this.db
                    .collection('volunteerProjects')
                    .get();
                let returnData: Array<VolunteerProjectType> = [];
                dataSnapshot.forEach((doc) => {
                    const data = {
                        ...doc.data(),
                        documentId: doc.id,
                    };
                    console.log('documnetId', data);
                    returnData.push(data as VolunteerProjectType);
                });
                return {
                    status: 'ok',
                    payload: returnData,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e,
                };
            }
        } else {
            return null;
        }
    }
    async deleteVolunteerProject(id: string) {
        if (id) {
            try {
                await this.db.collection('volunteerProjects').doc(id).delete();
                return {
                    payload: 'successfully deleted volunteer project',
                    status: 200,
                };
            } catch (e: any) {
                return {
                    payload:
                        'something went wrong while deleting volunteer project',
                    status: 500,
                };
            }
        } else {
            return null;
        }
    }
    async deleteProject(id: string) {
        if (id) {
            try {
                await this.db.collection('projects').doc(id).delete();
                return {
                    payload: 'successfully deleted project',
                    status: 200,
                };
            } catch (e: any) {
                return {
                    payload: 'something went wrong while deleting project',
                    status: 500,
                };
            }
        } else {
            return null;
        }
    }

    async updateAvatarForOrganizationMember(
        image: File | null,
        organizationId: string,
        ourMembers: TeamMember[],
        isEdit: boolean,
        member: TeamMember,
    ): Promise<NetworkResponse> {
        if (this.auth.currentUser) {
            const imageName = `${organizationId}-${member.id}.jpg`;
            let downloadUrl = '';
            try {
                if (image) {
                    const compressedFile: any = await prepareAvatar(image);
                    const uploadTask = this.storage
                        .ref(`organizations/${imageName}`)
                        .put(compressedFile);
                    downloadUrl = (await waitForUploadTaskCompletion(
                        uploadTask,
                    )) as string;
                } else {
                    downloadUrl = member.avatar;
                }
                const teamMember = {
                    ...member,
                    avatar: downloadUrl,
                } as TeamMember;
                let updatedMemberList = [];
                if (isEdit) {
                    updatedMemberList = _map(ourMembers, (item) => {
                        return item.id === member.id ? teamMember : item;
                    });
                    //ourMembers.filter(teamMember => teamMember.id !== member.id)
                } else {
                    updatedMemberList = [...ourMembers, teamMember];
                }
                await this.db
                    .collection('organizations')
                    .doc(organizationId)
                    .set(
                        {
                            ourMembers: updatedMemberList,
                        },
                        { merge: true },
                    );
                return {
                    payload: {
                        teamMembers: updatedMemberList,
                        message: 'Member saved successfully',
                    },
                    status: 200,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e.message,
                };
            }
        }
        return {
            status: 'error',
            payload: { message: 'Unauthorized' },
        };
    }

    async deleteAvatarForOrganizationMember(
        url: string,
        organizationId: string,
        ourMembers: TeamMember[],
        member: TeamMember,
    ): Promise<NetworkResponse> {
        if (this.auth.currentUser) {
            try {
                url &&
                    this.storage
                        .refFromURL(url)
                        .delete()
                        .then((res) => {
                            console.log('Deleted response', res);
                        });
                const updatedMemberList = ourMembers.filter(
                    (teamMember) => teamMember.id !== member.id,
                );
                await this.db
                    .collection('organizations')
                    .doc(organizationId)
                    .set(
                        {
                            ourMembers: updatedMemberList,
                        },
                        { merge: true },
                    );
                return {
                    payload: {
                        teamMembers: updatedMemberList,
                        message: 'Member deleted successfully',
                    },
                    status: 200,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e.message,
                };
            }
        }
        return {
            status: 'error',
            payload: { message: 'Unauthorized' },
        };
    }

    async updateCoverPicture(
        image: File,
        organizationId: string,
        docId: string,
    ) {
        if (this.auth.currentUser) {
            const imageName = `${organizationId}-${docId}.jpg`;
            const compressedFile: any = await prepareCoverImage(image);
            const uploadTask = this.storage
                .ref(`organizations/${imageName}`)
                .put(compressedFile);
            try {
                const downloadUrl = await waitForUploadTaskCompletion(
                    uploadTask,
                );
                await this.db.collection('users').doc(docId).set(
                    {
                        cover_picture_url: downloadUrl,
                    },
                    { merge: true },
                );
                await this.db
                    .collection('organizations')
                    .doc(organizationId)
                    .set(
                        {
                            cover_picture_url: downloadUrl,
                        },
                        { merge: true },
                    );
            } catch (e: any) {
                return e;
            }
        }
        return null;
    }

    async sendNotificationToVolunteers(
        message: string,
        organizationId: string,
        volunteerIds: string[],
    ) {
        if (this.auth.currentUser) {
            try {
                await this.db
                    .collection('organization-volunteer-notification')
                    .add({
                        message,
                        volunteers: volunteerIds,
                        organizationId,
                        created_at: firebase.firestore.Timestamp.fromDate(
                            new Date(),
                        ),
                    });
                return {
                    payload: {
                        message: 'Notification successfully sent',
                    },
                    status: 200,
                };
            } catch (e: any) {
                return {
                    status: 'error',
                    payload: e.message,
                };
            }
        }
    }

    async getOrganizationBroadcastMessages(organizationId: string) {
        try {
            const messages: BroadCastType[] = [];
            const snapshot = await this.db
                .collection('organization-volunteer-notification')
                .where('organizationId', '==', organizationId)
                .get();
            if (snapshot) {
                snapshot.forEach((doc) =>
                    messages.push(doc.data() as BroadCastType),
                );
            }
            return {
                status: 'success',
                payload: messages.length ? messages : [],
            };
        } catch (e: any) {
            return {
                status: 'error',
                payload: e.message,
            };
        }
    }

    getLoggedInUserId() {
        if (this.auth.currentUser) {
            return this.auth.currentUser.uid;
        } else {
            return null;
        }
    }

    async getOrganizationsByVolunteerId(volunteerId: string) {
        const volunteerProjects = await this.getVolunteerProjects(volunteerId);
        if (volunteerId && volunteerProjects.length) {
            let snapshot = await this.db
                .collection('organizations')
                .where('uid', '==', volunteerProjects[0].organization_id)
                .get();
            if (snapshot) {
                let organizationId = '';
                snapshot.forEach((doc) => {
                    organizationId = doc.id;
                });
                return organizationId;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getCurrentTimestamp() {
        return firebase.firestore.Timestamp.now();
    }

    getLastTimestampForMessage(notification: BroadCastType) {
        if (notification.timestamp) {
            return notification.timestamp;
        } else {
            return firebase.firestore.Timestamp.fromDate(new Date(0));
        }
    }

    async updateBroadCastNotificationTimestamp(
        notification: BroadCastType,
        docId: string,
    ) {
        try {
            await this.db
                .collection('organization-volunteer-notification')
                .doc(docId)
                .set(notification);
            return {
                payload: {
                    message: 'Notification updated sent',
                },
                status: 200,
            };
        } catch (err) {
            return {
                payload: {
                    message: 'Something went wrong',
                },
                status: 500,
            };
        }
    }

    async createVolunteerRequestToJoinOrg(
        projectId: string,
        organizationId: string,
        volunteerId: string,
        volunteerName: string,
    ) {
        if (this.auth.currentUser) {
            try {
                const currentProject = (await this.getProjectDetailsById(
                    projectId,
                )) as ProjectType;
                const organizationDocument = await this.db
                    .collection('organizations')
                    .where('uid', '==', organizationId)
                    .get();
                if (!organizationDocument.empty) {
                    let orgDocId = '';
                    organizationDocument.forEach((organization) => {
                        orgDocId = organization.id;
                    });
                    const requestDetails = [
                        {
                            status: 'Pending',
                            organizationId,
                            projectId,
                            volunteerId,
                            volunteerName,
                            projectName: currentProject.name,
                        },
                    ];
                    await this.db
                        .collection('organizations')
                        .doc(orgDocId)
                        .set(
                            {
                                volunteerRequest:
                                    firebase.firestore.FieldValue.arrayUnion(
                                        ...requestDetails,
                                    ),
                            },
                            { merge: true },
                        );

                    await this.db
                        .collection('users')
                        .doc(volunteerId)
                        .set(
                            {
                                requestStatus:
                                    firebase.firestore.FieldValue.arrayUnion(
                                        ...requestDetails,
                                    ),
                            },
                            { merge: true },
                        );
                }
                return {
                    payload: {
                        message: 'Volunteer request updated successfully',
                    },
                    status: 200,
                };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error', error: 'Unauthorized' };
    }

    async updateVolunteerRequest(
        status: string,
        volunteerId: string,
        organizationId: string,
        projectId: string,
    ) {
        if (this.auth.currentUser) {
            try {
                const organizationDocument = await this.db
                    .collection('organizations')
                    .where('uid', '==', organizationId)
                    .get();

                //Update Organization Document for approval
                if (!organizationDocument.empty) {
                    organizationDocument.forEach((organization) => {
                        const volunteerApprovalList = organization.data()
                            .volunteerRequest as ApprovalType[];
                        const updatedArray = volunteerApprovalList.map(
                            (item) => {
                                if (item.projectId === projectId) {
                                    // Update status property
                                    return {
                                        ...item,
                                        status,
                                    };
                                } else {
                                    return item;
                                }
                            },
                        );
                        organization.ref.update({
                            volunteerRequest: updatedArray,
                        });
                    });
                }

                //Update User Document for approval
                const userDocument = await this.db
                    .collection('users')
                    .doc(volunteerId)
                    .get();

                if (userDocument.exists) {
                    const userDoc = userDocument.data() as UserType;
                    const updatedArray = userDoc.requestStatus.map((item) => {
                        if (item.projectId === projectId) {
                            // Update status property
                            return {
                                ...item,
                                status,
                            };
                        } else {
                            return item;
                        }
                    });
                    userDocument.ref.update({
                        requestStatus: updatedArray,
                    });
                }

                //Only add volunteer in project if he is approved
                if (status === 'Approved') {
                    //Update Project Document for approval
                    await this.db
                        .collection('projects')
                        .doc(projectId)
                        .update({
                            team_member_ids:
                                firebase.firestore.FieldValue.arrayUnion(
                                    volunteerId,
                                ),
                            committed_partners:
                                firebase.firestore.FieldValue.increment(1),
                        });
                }

                return {
                    payload: {
                        message: 'Volunteer request updated successfully',
                    },
                    status: 200,
                };
            } catch (e: any) {
                return { status: 'error', error: e };
            }
        }
        return { status: 'error', error: 'Unauthorized' };
    }

    // Update All users isApproved property
    async updateAllUsersIsApproved() {
        let returnData: DocumentData = await this.db
            .collection('organizations')
            .get();
        const users: string[] = [];
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 70);
        returnData.forEach(async (doc: any) => {
            users.push(doc.data());
            if (this.auth.currentUser) {
                await this.db.collection('organizations').doc(doc.id).set(
                    {
                        createdAt: oneWeekAgo,
                    },
                    { merge: true },
                );
            }
        });

        console.log(users);
        return users;
    }

    handleCallFunction = async () => {
        try {
            // Ensure user is authenticated (optional)

            if (this.auth.currentUser) {
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

                const querySnapshot = await this.db
                    .collection('users')
                    .where('created_at', '>=', oneWeekAgo)
                    .get();

                const recentlyAddedOrganizations: UserType[] = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as UserType;
                    data.id = doc.id.toString();
                    recentlyAddedOrganizations.push(data);
                });

                // Sort recentlyAddedOrganizations based on createdAt in descending order
                recentlyAddedOrganizations.sort(
                    (a, b) => b.created_at.toMillis() - a.created_at.toMillis(),
                );
                console.log(
                    '🚀 ~ sendEmailToAdmin ~ recentlyAddedOrganizations:',
                    recentlyAddedOrganizations,
                );
                for (const user of recentlyAddedOrganizations) {
                    if (user.account_type !== ACCOUNT_TYPES[3]) {
                        if (!user.skills?.length || !user.projects?.length) {
                            await this.sendEmailToUserToUpdateProfile(
                                user.email,
                                user.first_name,
                            );
                        }
                    } else {
                        await this.handleOrganizationUser(user);
                    }
                }
            }
        } catch (error) {
            console.error('Error calling function:', error);
        }
    };

    //TODO: Need to delete these functions after prod build
    private async sendEmailToUserToUpdateProfile(
        email: string,
        firstName: string,
    ) {
        const data = {
            to: email,
            html: `Dear ${firstName},<br />
                                       We noticed that your profile is missing some important information. Please take a moment to update your skills and projects on your profile.<br />
                                       Thank you for your cooperation.<br />
                                       Best regards,<br />
                                       N-Vest Partners`,
            subject: 'Please Update Your Profile',
        };
        const result = await this.sendEmail(data);
        console.log('🚀 ~ Firebase ~ handleCallFunction= ~ result:', result);
    }
    private async handleOrganizationUser(user: UserType) {
        // TODO: Implement logic for handling Organization users
        console.log(`Handling Organization user: ${user.id}`);
        const organization = await this.db
            .collection('organizations')
            .doc(user.organization_id)
            .get();
        if (organization.exists) {
            const data = organization.data() as OrganizationType;
            debugger;
            if (!data.mission || !data.vision || !data.success_stories) {
                await this.sendEmailToUserToUpdateProfile(
                    user.email,
                    user.first_name,
                );
            }
        }
    }

    async getAccountsForApprovals() {
        try {
            const querySnapshotApproved = await this.db
                .collection('users')
                .where('isApproved', '==', false)
                .get();

            const querySnapshotRejected = await this.db
                .collection('users')
                .where('isRejected', '==', false)
                .get();

            // Combine the results from both queries
            const users: UserType[] = [];

            querySnapshotApproved.forEach((doc) => {
                const data = doc.data() as UserType;
                data.id = doc.id.toString();
                users.push(data);
            });

            querySnapshotRejected.forEach((doc) => {
                const data = doc.data() as UserType;
                data.id = doc.id.toString();
                // Avoid duplicates by checking if the user is already in the array
                if (!users.some((user) => user.id === data.id)) {
                    users.push(data);
                }
            });
            console.log(users);
            return users;
        } catch (error) {
            console.error('Error calling function:', error);
            return [];
        }
    }

    async approveUser(userId: string) {
        try {
            await this.db.collection('users').doc(userId).set(
                {
                    isApproved: true,
                },
                { merge: true },
            );
            return {
                payload: {
                    message: 'User approved successfully',
                },
                status: 200,
            };
        } catch (e: any) {
            return {
                status: 'error',
                payload: e.message,
            };
        }
    }

    async rejectUser(userId: string, setRejectionReason: string) {
        try {
            await this.db.collection('users').doc(userId).set(
                {
                    setRejectionReason,
                    isRejected: true,
                },
                { merge: true },
            );
            return {
                payload: {
                    message: 'User rejected successfully',
                },
                status: 200,
            };
        } catch (e: any) {
            return {
                status: 'error',
                payload: e.message,
            };
        }
    }

    // Donation functions
    async saveDonationInformation(data: any) {
        let createSaveDonationInformation =
            this.functions.httpsCallable('saveUserData');
        try {
            const returnedValues = await createSaveDonationInformation(data);
            return returnedValues;
        } catch (error) {
            console.log('ERROR OCCURRED WHEN SAVING DATA', error);
        }
    }

    async getFirebaseEnvironment() {
        const environment = await this.functions.httpsCallable(
            'getFirebaseEnvironment',
        )();
        return environment;
    }

    async createStripeSubscription(data: {
        customerId?: string;
        customPrice: number;
        payment_type: string;
        productId?: string;
        payment_method?: string;
        paymentMethodId?: string;
    }) {
        let createSubscription = this.functions.httpsCallable(
            'createPaymentIntent',
        );
        try {
            const returnedValues = await createSubscription(data);
            return returnedValues;
        } catch (error) {
            console.log('ERROR OCCURED WHEN CREATING STRIPE SUBSCRIPTION');
        }
    }

    async createStripeProduct(data: {
        customerId: string;
        name: string;
        price: number;
    }) {
        try {
            let createProduct = this.functions.httpsCallable('createProduct');
            const returnedValues = await createProduct(data);
            return returnedValues;
        } catch (error) {
            console.log('ERROR CREATING PRODUCT', error);
        }
    }

    async createStripeCustomer(data: {
        email: string;
        name: string;
        paymentMethodId: string;
    }) {
        const addStripeCustomer = this.functions.httpsCallable(
            'createStripeCustomer',
        );
        try {
            const returnedValues = await addStripeCustomer(data);
            return returnedValues;
        } catch (error) {
            console.log('ERROR OCCURED WHEN CREATING STRIPE CUSTOMER');
        }
    }
}

const firebaseInstance = new Firebase();
export default firebaseInstance;
