import React, { createContext } from 'react';
import useVolunteerProjects from 'hooks/useVolunteerProjects';

export const ProjectsContext = createContext();

const ProjectsContextProvider = (props) => {
    const { projects, isLoading } = useVolunteerProjects();
    return (
        <ProjectsContext.Provider value={{ projects, isLoading }}>
            {props.children}
        </ProjectsContext.Provider>
    );
};

export default ProjectsContextProvider;
